@font-face {
    font-family: 'ElegantClassica';
    src: url('./assets/fonts/elegante_classica/elegante_classica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    color: white;
}

.content {
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 20px;
    padding-bottom: 100px;
}

#titulo {
    color: white;
    font-size: 25px;
    text-align: center;
    padding-top: 10px !important;
    font-family: 'ElegantClassica';
    background-image: url('./assets/images/titulo-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
}

#logo img {
    width: 80px;
}

#pastores #direita {
    text-align: right;
}

#imagem-pastores {
    width: 300px;
}

#nome-pastores {
    width: 220px;
}

#pastores #esquerda {
    position: absolute;
}

.foto-carrosel {
    height: 400px;
    background-size: cover;
    background-position: center center;
}

@media screen and (min-width: 800px) {
    .content-container {
        width: 800px;
        margin: 0 auto;
    }
}
